import React from 'react';
import { Button } from 'semantic-ui-react';

import './Launch.css';
import Logo from './assets/logo.svg'
import clientConfig from './client.js';
const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = 'Ford Pro Metaverse';
  title?: string = 'title';
}

interface LaunchProps {
  Launch: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  return (
    <div id="launchContainer">
      <div>
        <h1>{client.description}</h1>
        <Button size="massive" color="blue" circular icon="play" onClick={() => props.Launch()}></Button>
      </div>
      <img alt="PureWeb Logo" src={Logo} style={{ width: 100, position: 'absolute', bottom: 50, right: 10 }} />
    </div>
  );
};
