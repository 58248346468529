import React, {useState} from 'react';
import App from './App';
import './Home.css';

import Dealer from './assets/dealer.svg';
import Charger from './assets/charger.svg';
import Updates from './assets/updates.svg';
import Signin from './assets/signin.svg';
import Logo from './assets/logo.svg';

import Main2 from './assets/main-2.jpeg';
import Main3 from './assets/main-3.jpeg';

import SocialFacebook from './assets/social-facebook.png';
import SocialLinkedin from './assets/social-linkedin.png';
import SocialTwitter from './assets/social-twitter.png';
import SocialYoutube from './assets/social-youtube.png';

import FooterLogo from './assets/footer-logo.png';


interface HomeProps {}

export const HomeView: React.FC<HomeProps> = (props: HomeProps) => {

  let [appActive, setAppActive] = useState(false);

  return (
    <div id="homeContainer">
        <header className="header">
            <ul>
                <li>
                    <img src={Dealer} alt="Locate Dealer and Service" /> 
                    Locate Dealer &amp; Service
                </li>
                <li>
                    <img src={Charger} alt="Find a Charger" />
                    Find a Charger
                </li>
                <li>
                    <img src={Updates} alt="Get Updates" />
                    Get Updates
                </li>
            </ul>
            <p>
                <img src={Signin} alt="Sign In" />
                Sign In
            </p>
        </header>
        <nav className="main-nav">
            <div className="nav-content">
                <ul>
                    <li className="nav-logo">
                        <img src={Logo} alt="Ford Pro Logo" />
                    </li>
                    <li>Software</li>
                    <li>Service</li>
                    <li>Charging</li>
                    <li>Financing</li>
                    <li>Vehicles</li>
                </ul>

                <input type="text" placeholder="Search" />
            </div>
        </nav>
        <main className="main-content">
            { appActive ?
                (<div className="unreal-container">
                    <div className="unreal-app">
                        <App />
                    </div>
                </div>) :
                (<div className="hero">
                    <div className="hero-banner">
                        <h1>Explore<br />Ford Pro</h1>
                        <button type="button" onClick={ () => setAppActive(true) }>Ford Pro Metaverse</button>
                    </div>
                </div>)
            }
            <div className="row">
                <div className="item">
                    <div className="description">
                        <h2>Ford Pro™ Intelligence</h2>
                        <p>Use the power of data to empower your fleet.</p>
                        <button type="button">Learn More</button>
                    </div>
                    <img className="image" src={Main2} alt="Ford Pro Intelligence Banner" />
                </div>
                <div className="item">
                    <div className="description">
                        <h2>Ford Pro Charging</h2>
                        <p>Get full coverage solutions for any charging need.</p>
                        <button type="button">Learn More</button>
                    </div>
                    <img className="image" src={Main3} alt="Ford Pro Charging Banner" />
                </div>
            </div>
        </main>

        <footer className="footer">
            <div className="footer-content">
                <div className="links">
                    <div className="column">
                        <h3>Resources</h3>
                        <ul>
                            <li>Glossary</li>
                        </ul>
                    </div>
                    <div className="column">
                        <h3>Legal</h3>
                        <ul>
                            <li>Terms &amp; Conditions</li>
                            <li>Do Not Sell My Personal Information</li>
                            <li>Privacy</li>
                            <li>Cookie Settings</li>
                            <li>Third-Party Trademarks</li>
                        </ul>
                    </div>
                    <div className="column">
                        <h3>Help</h3>
                        <ul>
                            <li>Contact Us</li>
                            <li>Accessibility</li>
                            <li>AdChoices</li>
                            <li>Site Map</li>
                        </ul>
                    </div>
                </div>
                <div className="social">
                    <h2>Follow Us</h2>
                    <ul>
                        <li>
                            <img src={SocialFacebook} alt="Facebook Icon" />
                        </li>
                        <li>
                            <img src={SocialLinkedin} alt="Linkedin Icon" />
                        </li>
                        <li>
                            <img src={SocialTwitter} alt="Twitter Icon"/>
                        </li>
                        <li>
                            <img src={SocialYoutube} alt="YouTube Icon" />
                        </li>
                    </ul>
                </div>
                <div className="logo">
                    <img className="footer-logo" src={FooterLogo} alt="Ford Pro Logo" />
                </div>
            </div>
        </footer>
    </div>
  );
};
